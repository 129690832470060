import {
  FETCH_COMPANY_WALLET_REQUESTED,
  FETCH_COMPANY_WALLET_SUCCESS,
  FETCH_COMPANY_WALLET_FAIL,
  ADD_COMPANY_WALLET_REQUESTED,
  ADD_COMPANY_WALLET_SUCCESS,
  ADD_COMPANY_WALLET_FAIL,
  ADD_COMPANY_WALLET_CLEAR,
  DELETE_COMPANY_WALLET_REQUESTED,
  DELETE_COMPANY_WALLET_SUCCESS,
  DELETE_COMPANY_WALLET_FAIL,
  EDIT_COMPANY_WALLET_REQUESTED,
  EDIT_COMPANY_WALLET_SUCCESS,
  EDIT_COMPANY_WALLET_FAIL,
  EDIT_COMPANY_WALLET_CLEAR
} from "./actionTypes";

export const fetchCompanyWallets = (params = {}) => {
  return {
    type: FETCH_COMPANY_WALLET_REQUESTED,
    payload: params
  };
};
export const fetchCompanyWalletsSuccess = (data) => {
  return {
    type: FETCH_COMPANY_WALLET_SUCCESS,
    payload: data
  };
};
export const fetchCompanyWalletsFail = (error) => {
  return {
    type: FETCH_COMPANY_WALLET_FAIL,
    payload: { error }
  };
};

export const addCompanyWallet = (params = {}) => {
  return {
    type: ADD_COMPANY_WALLET_REQUESTED,
    payload: params
  };
};
export const addCompanyWalletSuccess = (data) => {
  return {
    type: ADD_COMPANY_WALLET_SUCCESS,
    payload: data
  };
};
export const addCompanyWalletFail = (error) => {
  return {
    type: ADD_COMPANY_WALLET_FAIL,
    payload: { error }
  };
};
export const addCompanyWalletClear = (data) => {
  return {
    type: ADD_COMPANY_WALLET_CLEAR,
    payload: data
  };
};

export const deleteCompanyWallet = (params) => {
  return {
    type: DELETE_COMPANY_WALLET_REQUESTED,
    payload: params
  };
};
export const deleteCompanyWalletSuccess = (data) => {
  return {
    type: DELETE_COMPANY_WALLET_SUCCESS,
    payload: data
  };
};
export const deleteCompanyWalletFail = (error) => {
  return {
    type: DELETE_COMPANY_WALLET_FAIL,
    payload: { error }
  };
};

export const editCompanyWallet = (params) => {
  return {
    type: EDIT_COMPANY_WALLET_REQUESTED,
    payload: params
  };
};
export const editCompanyWalletSuccess = (data) => {
  return {
    type: EDIT_COMPANY_WALLET_SUCCESS,
    payload: data
  };
};
export const editCompanyWalletFail = (error) => {
  return {
    type: EDIT_COMPANY_WALLET_FAIL,
    payload: { error }
  };
};
export const editCompanyWalletClear = (data) => {
  return {
    type: EDIT_COMPANY_WALLET_CLEAR,
    payload: data
  };
};