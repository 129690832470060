export const FETCH_COMPANY_WALLET_REQUESTED = "FETCH_COMPANY_WALLET_REQUESTED";
export const FETCH_COMPANY_WALLET_SUCCESS = "FETCH_COMPANY_WALLET_SUCCESS";
export const FETCH_COMPANY_WALLET_FAIL = "FETCH_COMPANY_WALLET_FAIL";

export const ADD_COMPANY_WALLET_REQUESTED = "ADD_COMPANY_WALLET_REQUESTED";
export const ADD_COMPANY_WALLET_SUCCESS = "ADD_COMPANY_WALLET_SUCCESS";
export const ADD_COMPANY_WALLET_FAIL = "ADD_COMPANY_WALLET_FAIL";
export const ADD_COMPANY_WALLET_CLEAR = "ADD_COMPANY_WALLET_CLEAR";

export const DELETE_COMPANY_WALLET_REQUESTED = "DELETE_COMPANY_WALLET_REQUESTED";
export const DELETE_COMPANY_WALLET_SUCCESS = "DELETE_COMPANY_WALLET_SUCCESS";
export const DELETE_COMPANY_WALLET_FAIL = "DELETE_COMPANY_WALLET_FAIL";

export const EDIT_COMPANY_WALLET_REQUESTED = "EDIT_COMPANY_WALLET_REQUESTED";
export const EDIT_COMPANY_WALLET_SUCCESS = "EDIT_COMPANY_WALLET_SUCCESS";
export const EDIT_COMPANY_WALLET_FAIL = "EDIT_COMPANY_WALLET_FAIL";
export const EDIT_COMPANY_WALLET_CLEAR = "EDIT_COMPANY_WALLET_CLEAR";