import {
  call, put, takeEvery
} from "redux-saga/effects";
import * as wallet from "apis/companyWallet";
import {
  fetchCompanyWalletsSuccess,
  fetchCompanyWalletsFail,
  addCompanyWalletSuccess,
  addCompanyWalletClear,
  addCompanyWalletFail,
  deleteCompanyWalletSuccess,
  deleteCompanyWalletFail,
  editCompanyWalletSuccess,
  editCompanyWalletClear,
  editCompanyWalletFail
} from "./actions";
import {
  FETCH_COMPANY_WALLET_REQUESTED,
  ADD_COMPANY_WALLET_REQUESTED,
  DELETE_COMPANY_WALLET_REQUESTED,
  EDIT_COMPANY_WALLET_REQUESTED
} from "./actionTypes";
import { showSuccessNotification } from "store/notifications/actions";
 
function * fetchCompanyWallets(params){
  try {
    const data = yield call(wallet.getDetails, params);
    yield put (fetchCompanyWalletsSuccess(data));
  } catch (error){
    yield put(fetchCompanyWalletsFail({ error: error.message }));
  }
}

function * addCompanyWallet(params){
  try {
    const data = yield call(wallet.postWallet, params);
    const { result } = data;
    yield put(addCompanyWalletSuccess(result));
    yield put(showSuccessNotification("Bank account created successfully"));
    yield put(addCompanyWalletClear());
  } catch (error){
    yield put(addCompanyWalletFail({ error: error.message }));
  }
}
  
function * deleteCompanyWallet(params){
  try {
    const data = yield call(wallet.deleteWallet, params);
    yield put(deleteCompanyWalletSuccess(data));
  } catch (error){
    yield put(deleteCompanyWalletFail({ error: error.message }));
  }
}
  
function * editCompanyWallet(params){
  try {
    const data = yield call(wallet.updateWallet, params);
    yield put(editCompanyWalletSuccess(data));
    yield put(showSuccessNotification("Bank account updated successfully"));
    yield put(editCompanyWalletClear());
  } catch (error){
    yield put(editCompanyWalletFail({ error: error.message }));
  }
}
  
function * companyWalletSaga() {
  yield takeEvery(FETCH_COMPANY_WALLET_REQUESTED, fetchCompanyWallets);
  yield takeEvery(ADD_COMPANY_WALLET_REQUESTED, addCompanyWallet);
  yield takeEvery(DELETE_COMPANY_WALLET_REQUESTED, deleteCompanyWallet);
  yield takeEvery(EDIT_COMPANY_WALLET_REQUESTED, editCompanyWallet);
}
  
export default companyWalletSaga;