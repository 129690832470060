import React, { 
  useState, 
  useEffect, 
  useReducer 
} from "react";
import { useDispatch as useReduxDispatch, useSelector } from "react-redux";
import {
  Modal, Button,
  ModalHeader,
  ModalBody,
  Label,
  Input,
} from "reactstrap";
import {
  AvForm, AvField
} from "availity-reactstrap-validation";
import Loader from "components/Common/Loader";
import AvFieldSelect from "components/Common/AvFieldSelect";

import { updateAccountType } from "store/actions";
import CustomCreatableSelect from "components/Common/CustomCreatableSelect";
import SearchableCountriesDropdown from "components/Common/SearchableCountriesDropdown";

const COUNTRIES = ["All Countries", "Specific Countries"];

function EditSwapType({ show, toggle, selectedAccountType }) {
  const reduxDispatch = useReduxDispatch();
  const { updating, error } = useSelector((state) => state.tradingAccountReducer);
  const [selectedCountries, setSelectedCountries] = useState([]);
  const [swapGroups, setSwapGroups] = useState([]);
  const [swapFreeGroups, setSwapFreeGroups] = useState([]);
  const [countriesOption, setCountriesOption] = useState("");
  console.log(selectedAccountType);
  const [useDefault, setUseDefault] = useState(false);

  useEffect(() => {
    if (selectedAccountType) {
      const newSwapGroups = [
        {
          value: selectedAccountType?.currencies?.[0]?.groupPath,
          label: `${selectedAccountType?.currencies?.[0]?.groupPath} (Default)` 
        },
        ...(selectedAccountType?.groups?.swapGroups?.map((item, index) => ({
          value: item,
          label: item,
        })) || [])
      ];

      const newSwapFreeGroups = selectedAccountType?.groups?.swapFreeGroups?.map((item, index) => ({
        value: item,
        label: index === 0 ? `${item} (Default)` : item,
      })) || [];

      setSwapGroups(newSwapGroups);
      setSwapFreeGroups(newSwapFreeGroups);
      setCountriesOption(selectedAccountType.countriesOption);
      setSelectedCountries(selectedAccountType.selectedCountries);
      setUseDefault(selectedAccountType?.useDefault);
    }
  }, [selectedAccountType]);

  useEffect(() => {
    (!updating && !error && show) && toggle();
  }, [updating]);


  const reducer = (state, action) => {
    switch (action.type) {
      case "UPDATE_SWAP_GROUPS":
      case "UPDATE_SWAP_FREE_GROUPS":
        return {
          ...state,
          [action.key]: action[action.key],
        };
      default:
        return state;
    }
  };

  const [groups, dispatch] = useReducer(reducer, { 
    swapGroups: swapGroups || [], 
    swapFreeGroups: swapFreeGroups || [] 
  });

  const handleSubmit = (e, v) => {
    delete v.countries;
    reduxDispatch(updateAccountType(selectedAccountType._id, {
      groups: {
        swapGroups: groups.swapGroups.length > 0 ? groups.swapGroups : swapGroups.map((group) => group.value),
        swapFreeGroups: groups.swapFreeGroups.length > 0 ? groups.swapFreeGroups : swapFreeGroups.map((group) => group.value)
      },
      countriesOption,
      selectedCountries: countriesOption === "All Countries" ? [] : selectedCountries,
      useDefault
    }));
  };

  return (
    <Modal isOpen={show} toggle={toggle} centered={true}>
      <ModalHeader toggle={toggle} tag="h4">
        Edit Account Type
      </ModalHeader>
      <ModalBody>
        <AvForm
          className="px-4"
          onValidSubmit={handleSubmit}
        >
          <AvFieldSelect
            name="countriesOption"
            label="Countries Option"
            placeholder="Select Countries Option"
            ismulti={false}
            options={COUNTRIES.map((type) => ({
              value: type,
              label: type
            }))}
            onChange={setCountriesOption}
            value={countriesOption}
            validate={{
              required: {
                value: true,
                errorMessage: "Countries Option is required"
              }
            }}
          />
          {
            countriesOption === "Specific Countries" && (
              <SearchableCountriesDropdown selectedCountries={selectedCountries} setSelectedCountries={setSelectedCountries} />
            )
          }
          <div className="my-3">
            <Label>Swap Groups</Label>
            <CustomCreatableSelect
              isMulti
              isClearable
              disableDeleteButton={false}
              disableCreateButton={false}
              label="Swap Groups"
              placeholder="Please enter swap groups exactly as it appears"
              dispatchState={(passedData) =>
                dispatch({
                  type: "UPDATE_SWAP_GROUPS",
                  key: "swapGroups",
                  swapGroups: passedData,
                })
              }
              isSwap={true}
              value={swapGroups}
            />
          </div>
          <div className="mt-3">
            <Label>Swap Free Groups</Label>
            <CustomCreatableSelect
              isMulti
              isClearable
              disableDeleteButton={false}
              disableCreateButton={false}
              placeholder="Please enter swap-free groups exactly as it appears"
              dispatchState={(passedData) =>
                dispatch({
                  type: "UPDATE_SWAP_FREE_GROUPS",
                  key: "swapFreeGroups",
                  swapFreeGroups: passedData,
                })
              }
              value={swapFreeGroups}
            />
          </div>
          <div className="mt-3">
            <label>
              <Input
                className="me-2"
                type="checkbox"
                name="useDefaultAuto"
                value={useDefault}
                onChange={() => setUseDefault(!useDefault)}
                checked={useDefault}
              />
              {"Use Default Auto"}
            </label>
          </div>
          <div className="text-center mt-3 p-2">
            {updating ? <Loader /> : <Button disabled={updating} type="submit" color="primary">Edit</Button>}
          </div>
        </AvForm>
      </ModalBody>
    </Modal>
  );
}

export default EditSwapType;